/**
 *
 * To reorder items, a delta value must be included in patch request body.
 * The delta represents the change in index position.
 *
 * INDEXES FOR ORDERING PURPOSES START AT 1. THIS METHOD ASSUMES THAT THIS
 * IS TAKEN INTO ACCOUNT BEFORE NEWINDEX VALUE IS PASSED IN.
 *
 * @example ...
 * newIndex = 1
 * oldIndex = 3
 * delta = -2
 *
 * newIndex = 20
 * oldIndex = 10
 * delta = 10
 *
 * @param {string} oldIndex starting index
 * @param {string} newIndex new index
 * @returns {number} delta change in index
 */

export function getDelta(oldIndex: number, newIndex: number): number {
  return newIndex - oldIndex;
}

/** Make string sentence case
 * @param {string} option string to be transformed
 */
export function makeSentenceCase(option: string) {
  return option.charAt(0).toUpperCase() + option.slice(1);
}

export function convertToSlug(str: string): string {
  return str ? str.replace(/\s+/g, "-").toLowerCase() as string : '';
}

export const toQueryString = (obj:any) => "?".concat(Object.keys(obj).map(e => obj[e] ? `${encodeURIComponent(e)}=${encodeURIComponent(obj[e])}` : null).filter(e => !!e).join("&"));

import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";
import { DeleteObject } from "@/models/utility";
import { EventBus } from "@/events";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalActions } from "@/store/modules/global/types";
/**
 * In order to take advantage of UIKit's functionality, this component needs to used as below:
 * @example HTML:
 *  <div id="delete-modal" uk-modal ref="deleteModal">
 *   <ConfirmDelete {...props} class="uk-modal-dialog"/>
 *  </div>
 * @example METHOD:
 *    import UIkit from "uikit";
 *    triggerModal() {
 *      UIkit.modal(document.getElementById('delete-modal') as HTMLElement).show();
 *    }
 */
@Component({
  components: {
    Icon
  }
})
export default class ConfirmDelete extends Vue {
  @Prop() deleteData!: DeleteObject[];
  @Prop({ default: "item" }) type!: string;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  protected goDelete() {
    const toDelete = this.deleteData.filter(row => {
      return this.deleteCheck(row);
    });
    toDelete.forEach((row, index) => {
      EventBus.$emit(
        "deleteConfirmed",
        row.id,
        row.display_name ? row.display_name : row.filename,
        index === toDelete.length - 1 ? true : false
      );
    });
    this.$emit("close");
  }

  protected deleteCheck(item: DeleteObject): boolean {
    let deleteItem = false;
    switch (this.type) {
      case "category":
        if (item.is_leaf && item.product_count == 0) {
          deleteItem = true;
        }
        break;
      case "manufacturer":
        if (item.product_count == 0) {
          deleteItem = true;
        }
        break;
      case "product":
      case "part":
      case "attribute":
      case "asset":
        deleteItem = true;
        break;
    }
    return deleteItem;
  }
}
